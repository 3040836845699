import Experiments from '@wix/wix-experiments'
import {EVENT_MANAGE_EVENTS, EVENT_DELETE_EVENTS} from './events'
import {EVENTS_APP_DEF_ID} from './constants'
import {pagePanelEventsEnabled} from './experiments'

export const getAppManifestFactory = (
  getT: Function,
  getLocale: Function,
  getAppToken: Function,
  getSdk: Function,
  getExperiments: () => Experiments,
) => async () => {
  if (!pagePanelEventsEnabled(getExperiments())) {
    return {}
  }

  const t = getT()
  const locale = getLocale()
  const appToken = getAppToken()
  const sdk = getSdk()

  const {instance} = await sdk.document.tpa.app.getDataByAppDefId(appToken, EVENTS_APP_DEF_ID)

  return {
    pages: {
      applicationSettings: {
        default: {
          displayName: t('pagesPanelEventsSectionTitle'),
          helpId: '9e9821b7-9e29-4ca0-ad92-0bd08c1d15ec',
        },
      },
      applicationActions: {
        default: [
          {
            title: t('pagesPanelEventsSectionManage'),
            event: EVENT_MANAGE_EVENTS,
            icon: 'settingsAction',
          },
          {
            title: t('pagesPanelEventsSectionDelete'),
            event: EVENT_DELETE_EVENTS,
            icon: 'deleteRadio',
          },
        ],
      },
      pageActions: {
        default: [],
      },
      pageSettings: {
        default: [
          {
            title: t('pagesPanelEventsDetailsTabPageInfoTitle'),
            url: `https://events.wixapps.net/_api/wix-one-events-server/html/page-panel-info?locale=${locale}&instance=${instance}`,
            type: 'page_info',
            helpId: '9e9821b7-9e29-4ca0-ad92-0bd08c1d15ec',
          },
          {
            title: t('pagesPanelEventsDetailsTabLayoutsTitle'),
            type: 'layout',
          },
        ],
      },
      pageDescriptors: {
        default: {
          icon: 'tpaPageType',
        },
      },
    },
  }
}
