import type {EditorSDK, PlatformEvent} from '@wix/platform-editor-sdk'
import {DETAILS_PAGE_ID} from './constants'

export const EVENT_MANAGE_EVENTS = 'manageEvents'
export const EVENT_DELETE_EVENTS = 'deleteEvents'

interface HandlerParams {
  sdk: EditorSDK
  appToken: string
}

const handlers: {[key: string]: Function} = {
  [EVENT_MANAGE_EVENTS]: async ({sdk, appToken}: HandlerParams) => {
    await sdk.editor.openDashboardPanel(appToken, {url: 'events', closeOtherPanels: false})
    return sdk.tpa.app.refreshApp(appToken)
  },
  [EVENT_DELETE_EVENTS]: async ({sdk, appToken}: HandlerParams) => {
    // Deleting main page first causes confirmation popup to show up
    const eventsPages = await sdk.document.pages.getApplicationPages(appToken)
    const essentialPage = eventsPages.find((page: any) => page.tpaPageId === DETAILS_PAGE_ID)
    await sdk.document.pages.remove(appToken, {pageRef: {id: essentialPage.id}})

    // In theory app should already be deleted at this point but it is often not the case
    await sdk.document.tpa.app.delete(appToken)
  },
}

export const onEventFactory = (getAppToken: Function) => async (
  {eventType, eventPayload}: PlatformEvent,
  editorSDK: EditorSDK,
) => {
  await handlers?.[eventType]?.({sdk: editorSDK, payload: eventPayload, appToken: getAppToken()})
}
