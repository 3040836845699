import {
  installRegisteredApps,
  MA_APP_IDS,
  maybeInstallMembersArea,
  withMembersArea,
} from '@wix/members-area-integration-kit'
import type {EditorSDK, TPAComponentType} from '@wix/platform-editor-sdk'
import Experiments from '@wix/wix-experiments'
import {getAppManifestFactory} from './editor-script/app-manifest'
import {
  BASE_INSTALL_CONFIG,
  EVENTS_APP_DEF_ID,
  DETAILS_PAGE_ID,
  PAID_PLANS_APP_DEF_ID,
  ProgressBarConfig,
  PROMO_INSTALL_CONFIG,
  PROMO_UPDATE_CONFIG,
  SCHEDULE_PAGE_ID,
} from './editor-script/constants'
import {onEventFactory} from './editor-script/events'
import {
  fetchExperiments,
  installAgendaPageEnabled,
  installPageEnabled,
  pagePanelEventsEnabled,
} from './editor-script/experiments'
import {ensurePagesManaged} from './editor-script/pages-panel'
import {getTranslateFunction} from './editor-script/services/translations'
import {parseStaticsUrlFromEditorScriptUrl} from './editor-script/services/url'
import {waitALittle} from './editor-script/services/wait-a-little'

let sdk: EditorSDK
let appToken: string
let t: Function
let adi: boolean
let staticsUrl: string
let locale: string
let experiments: Experiments

const EventsApp = {
  editorReady: async (editorSDK: EditorSDK, token: string, options: any): Promise<any> => {
    const [localeResponse, experimentsResponse] = await Promise.all([
      editorSDK.environment.getLocale(),
      fetchExperiments(),
    ])

    locale = localeResponse
    experiments = experimentsResponse
    sdk = editorSDK
    appToken = token
    staticsUrl = parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl)
    t = await getTranslateFunction(staticsUrl, locale)
    adi = options?.origin?.type === 'ADI'

    if (installPageEnabled(experiments)) {
      installPage({editorSDK, token, pageId: DETAILS_PAGE_ID, title: t('pagesPanelEventsDetailsTitle')})
    }

    if (installAgendaPageEnabled(experiments)) {
      installPage({editorSDK, token, pageId: SCHEDULE_PAGE_ID, title: t('pagesPanelEventsScheduleTitle')})
    }

    await setAppAPI()

    if (pagePanelEventsEnabled(experiments)) {
      await ensurePagesManaged(editorSDK, token, t)
    }
  },
  handleAction: async (args: any) => {
    const {type, payload} = args

    switch (type) {
      case 'appInstalled':
        switch (payload.appDefinitionId) {
          case EVENTS_APP_DEF_ID:
            if (adi) {
              return
            }
            await installMembersArea()
            return
          default:
            return
        }
      case 'migrate':
        if (payload?.addPaidPlans) {
          return addPaidPlans()
        }
        if (payload?.addSchedulePage) {
          return installPage({
            editorSDK: sdk,
            pageId: SCHEDULE_PAGE_ID,
            token: appToken,
            title: t('pagesPanelEventsScheduleTitle'),
          })
        }
        break
      default:
        console.log(type, payload)
        return
    }
  },
  getAppManifest: getAppManifestFactory(
    () => t,
    () => locale,
    () => appToken,
    () => sdk,
    () => experiments,
  ),
  onEvent: onEventFactory(() => appToken),
}

const setAppAPI = async () => {
  const api: EventsApi = {
    installMembersArea: async () => installMembersArea(true),
    installMembersAreaSections,
  }
  await sdk.editor.setAppAPI(appToken, api)
}

const showProgressBar = (config: ProgressBarConfig): Promise<Function> => {
  return new Promise(async resolve => {
    await sdk.editor.openProgressBar(appToken, {
      title: t(config.title),
      totalSteps: 3,
      currentStep: 1,
      stepTitle: t(config.step1),
    })
    await waitALittle(config.timeBetweenSteps)
    await sdk.editor.updateProgressBar(appToken, {
      currentStep: 2,
      stepTitle: t(config.step2),
    })
    await waitALittle(config.timeBetweenSteps)
    await sdk.editor.updateProgressBar(appToken, {
      currentStep: 3,
      stepTitle: t(config.step3),
    })
    await waitALittle(800)
    resolve(() => {
      sdk.editor.closeProgressBar(appToken, {})
    })
  })
}

const installMembersArea = async (isInstallingFromMembersPromo = false) => {
  const progressBarPromise = isInstallingFromMembersPromo
    ? showProgressBar(PROMO_INSTALL_CONFIG)
    : showProgressBar(BASE_INSTALL_CONFIG)

  await maybeInstallMembersArea()
    .then(() => hideProgressBar(progressBarPromise))
    .catch(() => hideProgressBar(progressBarPromise, false))
  // sdk.editor.openProgressBar does not always resolve
}

const hideProgressBar = (progressBarPromise: Promise<Function>, shouldAwait = true) => {
  progressBarPromise.then(async close => {
    if (shouldAwait) {
      await waitALittle(2000)
    }
    close()
  })
}

const installMembersAreaSections = async () => {
  const progressBarPromise = showProgressBar(PROMO_UPDATE_CONFIG)
  await installRegisteredApps()
  progressBarPromise.then(async close => {
    await waitALittle(1500)
    close()
  })
}

const addPaidPlans = () => {
  return sdk.document.tpa.add.application(appToken, {appDefinitionId: PAID_PLANS_APP_DEF_ID})
}

const installPage = async ({
  editorSDK,
  token,
  pageId,
  title,
}: {
  editorSDK: EditorSDK
  token: string
  pageId: string
  title?: string
}) => {
  const pageInstalled = await editorSDK.tpa.isAppSectionInstalled(token, {
    sectionId: pageId,
    appDefinitionId: EVENTS_APP_DEF_ID,
  })
  if (!pageInstalled) {
    return editorSDK.tpa.add.component(token, {
      appDefinitionId: EVENTS_APP_DEF_ID,
      componentType: 'PAGE' as TPAComponentType.Page,
      page: {
        pageId,
        title,
        isHidden: true,
        shouldNavigate: false,
      },
    })
  }
}

const editorApp = withMembersArea(EventsApp, {
  installAutomatically: false,
  membersAreaApps: [MA_APP_IDS.ABOUT, MA_APP_IDS.MY_EVENTS, MA_APP_IDS.MY_WALLET],
})

export const editorReady = editorApp.editorReady
export const handleAction = editorApp.handleAction
export const onEvent = editorApp.onEvent
export const getAppManifest = editorApp.getAppManifest
